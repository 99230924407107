import React from 'react';
import Navbar from './Navbar';
import './Services.css';
import videoSrc from '../media/Services_BackgroundVid.mp4';
import codeLongGoldImg from '../media/Code_Long_Gold.png';
import reactImg from '../media/React.png';
import wordpressImg from '../media/WordPress.png';
import typingVideo from '../media/Typing.mp4';
import marketOne from '../media/Market_One.png';
import marketTwo from '../media/Market_Two.png';
import marketThree from '../media/Market_Three.png';
import marketFour from '../media/Market_Four.png';
import productDesigns from '../media/Product_Designs.png';
import whistleImg from '../media/WHISTLE.png';
import logoMartins from '../media/Logo_Martins.png';
import photographyOne from '../media/Photography_One.png';
import photographyTwo from '../media/Photography_Two.JPG';
import photographyThree from '../media/Photography_Three.JPG';
import Footer from './Footer';

const Services = () => {
    return (
        <div className="services-page">
            <Navbar />
            <div className="video-background">
                <video autoPlay muted loop>
                    <source src={videoSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <img src={codeLongGoldImg} alt="Code Long Gold" className="large-image centered-image" />
            <div className="services-content">
                <h1>Services</h1>
                <div className="text-content">
                    <p>We understand how difficult it is to stand out in this social media landscape where attention is the finest currency. Code Long can help you get more eyes on your brand and message, turning those viewers into consumers.</p>
                    
                    
                    <h2 id="websites">Websites</h2>
                    <p>With a professional platform to share your unique message to the world, you are unstoppable. 
                    Code Long Websites are made to reflect your brand’s personality in a visually impactful way.</p>
                    <img src={reactImg} alt="React" className="full-width-image" />
                    <p>This is more than a website, it’s a work of art. Each page reflects every tiny detail that makes your big vision important while breathing an air of elegance and sophistication into the design, all made possible with each line of code supporting such depth. This is perfect for those who wish to avoid constantly updating and changing information for their viewers, making it low maintenance, high results.</p>

                    <h2>WORDPRESS Website</h2>
                    <p>You are in control of how you want your website to look and feel the whole way through the design process for either approach be it REACT or WORDPRESS, however we understand that once your completely unique web design is finished, some of you may wish to make updates/changes to the content of your website for your audience in frequent fashion.</p>
                    <img src={wordpressImg} alt="WordPress" className="full-width-image" />
                    <p>WORDPRESS is a website builder software which is simple and easy to use. Once we complete your web design, you can easily modify and make changes to the content long after our work is finished. With your own login credentials, you will be able to access and make changes from anywhere and keep your audience up to date. To get a better look, check out some of the work we have done for St Martin's Designs.</p>

                    
                    <h2 id="copywriting">Copywriting</h2>
                    <p>Presence is important and how you present yourself is just as crucial for your success. Code Long knows how to write good copy that makes people intrigued to find out more about who you are and what you do. The secret is in layering your posts with interesting threads that draw curiosity.</p>
                    <video autoPlay muted loop className="full-width-video">
                        <source src={typingVideo} type="video/mp4" />
                    </video>
                    <p>We work to provide you with the most compelling social media posts, imagery such as posters, promotional materials, and emails so that your audience can grow and hear your message loud and clear. There is an art to formulating content that engages people with high levels of interest.</p>

                    
                    <h2 id="branding">Brand & Marketing</h2>
                    <p>The competition is fierce in this digital landscape of social media and the ones who stand out all have one thing in common, a strong branded logo and uniformed marketing materials which demonstrate who you are to newcomers and remind your existing consumers who you are what you do.</p>
                    <img src={marketOne} alt="Market One" className="full-width-image" />
                    <img src={marketTwo} alt="Market Two" className="full-width-image" />
                    <img src={marketThree} alt="Market Three" className="full-width-image" />
                    <img src={marketFour} alt="Market Four" className="full-width-image" />
                    <img src={productDesigns} alt="Product Designs" className="full-width-image" />
                    <p>Code Long uses Canva Pro to craft logos so that your target audience will know instantly who you are and remember your brand over your competitors.</p>
                    <img src={whistleImg} alt="WHISTLE" className="full-width-image" />
                    <img src={logoMartins} alt="Logo Martins" className="full-width-image" />
                    <img src={photographyOne} alt="Photography One" className="full-width-image" />
                    <img src={photographyTwo} alt="Photography Two" className="full-width-image" />
                    <img src={photographyThree} alt="Photography Three" className="full-width-image" />
                    <p>We also as part of our marketing services use professional photography equipment to produce digital media such as high quality photographs, professionally shot and edited videos. We use Apple’s industry standard video editing software ‘Final Cut Pro’ to achieve high quality videos.</p>
                    <p>If you wish to build an iconic and recognisable brand, Code Long can make it happen!</p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Services;