import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import SideMenu from './SideMenu';
import logo from '../media/Code_Long.png';

const Navbar = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const sideMenuRef = useRef(null);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const handleClickOutside = (event) => {
        if (sideMenuRef.current && !sideMenuRef.current.contains(event.target)) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        if (isMenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuOpen]);

    return (
        <>
            <nav className="navbar">
                <div className="navbar-left">
                    <span className="side-menu-icon" onClick={toggleMenu}>☰</span>
                </div>
                <div className="navbar-center">
                    <Link to="/">
                        <img src={logo} alt="Code Long Logo" className="logo" />
                    </Link>
                </div>
                <div className="navbar-right">
                    <ul className="menu-items">
                        <li><Link to="/services">Services</Link></li>
                        <li><Link to="/designs">Designs</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                </div>
            </nav>
            <div ref={sideMenuRef}>
                <SideMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
            </div>
        </>
    );
};

export default Navbar;
