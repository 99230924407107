import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Designs from './components/Designs';
import Services from './components/Services';  // Import the Services component

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/designs" element={<Designs />} />
        <Route path="/services" element={<Services />} /> {/* Add the Services route */}
      </Routes>
    </Router>
  );
};

export default App;
