import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import './Home.css';
import videoSrc from '../media/Code_Long.mp4';
import weRefineImg from '../media/We_Refine.png';
import threeSentencesImg from '../media/Three_Sentences.png';
import websitesGif from '../media/Websites.gif';
import copywritingGif from '../media/Copywriting.gif';
import brandingGif from '../media/Branding.gif';
import SSS from '../media/SSS.gif';
import testimonyOneGif from '../media/Testimony_One.gif';
import testimonyTwoGif from '../media/Testimony_Two.gif';
import Footer from './Footer';

const Home = () => {
    return (
        <div className="home">
            <Navbar />
            <div className="video-background">
                <video autoPlay muted loop>
                    <source src={videoSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>

            {/* SSS Image */}
            <div className="sss-container">
                <img src={SSS} alt="SSS" className="sss-image" />
            </div>

            {/* Grouped content below SSS */}
            <div className="content-group">
                {/* Three Sentences and GIFs - Always Visible */}
                <div className="content-container always-visible">
                    <img src={threeSentencesImg} alt="Three Sentences" className="three-sentences" />
                    <div className="gif-container">
                        <a href="/services#websites">
                         <img src={websitesGif} alt="Websites" className="gif-image" />
                         </a>
                         <a href="/services#copywriting">
                         <img src={copywritingGif} alt="Copywriting" className="gif-image" />
                         </a>
                          <a href="/services#branding">
                            <img src={brandingGif} alt="Branding" className="gif-image" />
                        </a>
                    </div>
                </div>

                {/* We Refine and Testimonies */}
                <div className="content-container always-visible">
                    <img src={weRefineImg} alt="We Refine" className="we-refine" />
                    <div className="description">
                        We use AI to enhance our skills, refining designs to create tailored solutions for clients.
                    </div>
                </div>

                {/* Testimony Content */}
                <div className="testimony-container">
                    <img src={testimonyOneGif} alt="Testimony One" className="testimony-image" />
                    <img src={testimonyTwoGif} alt="Testimony Two" className="testimony-image" />
                </div>
            </div>
            <Footer />
        </div>
    );
};



export default Home;