import React from 'react';
import Navbar from './Navbar';
import './About.css';
import videoSrc from '../media/About_BackgroundVid.mp4';
import fireworksVideo from '../media/Fireworks.mp4';
import codeLongGold from '../media/Code_Long_Gold.png';
import testimonyOne from '../media/Testimony_One.gif';
import testimonyTwo from '../media/Testimony_Two.gif';
import Footer from './Footer';


const About = () => {
  return (
    <div className="about">
      <Navbar />
      <div className="video-background">
        <video autoPlay muted loop>
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      {/* This extra div will act as a spacer to push the content down visually */}
      <div className="spacer"></div>
      <div className="content-overlay">
        <h1>About</h1>
        <p>As a naturally curious person, I have always taken an interest in those who have vision and drive, as this reflects so much of me. Combined with both a desire to help others and my skills in technology and the arts, this curiosity has helped me to build a business that stands for the visionaries, the businesses, professionals and people with ‘spark’.</p>
        <p>At Code Long, we take your spark and rather than let it fizzle out in the dark, we use it to light a spectacular fireworks display, showing the world just how bright and remarkable your vision truly is.</p>
        <div className="video-container">
          <video autoPlay muted loop>
            <source src={fireworksVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <p>We have already made so many positive impacts and wish to continue making more. Here are some testimonials</p>
        <img src={testimonyOne} alt="Testimonial One" className="large-image" />
        <img src={testimonyTwo} alt="Testimonial Two" className="large-image" />
        <p>We offer three main services, one of which being Web Design where we carefully craft bespoke, tailor-made websites for clients either with raw code or through a website builder software, all dependent on our clients' needs. We do Copywriting for our clients such as social media posts, posters and emails, using words which are engaging and spark curiosity in the reader. Also, Code Long offers Brand & Marketing packages where we can build you a brand designing logos and generating marketing materials with use of photography, video and other visual media.</p>
        <p>As professionals, our most powerful tool is our ears. We listen carefully to our clients, because we understand how important their story and vision is in the larger scheme of designs and content.</p>
        <p>At Code Long, we use our knowledge of social media and digital marketing to generate materials that appeal to your target demographic and grow your presence online.</p>
        <img src={codeLongGold} alt="Code Long Gold" className="large-image centered-image" />
        <p>If you have a spark then we’ll help light the fuse to success, find our details on the <a href="/contact" style={{ color: 'yellow' }}>Contact</a> page and get in touch today!</p>
        <Footer />
      </div>
    </div>
      
    
  );
};
        

export default About;