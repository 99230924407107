import React from 'react';
import Navbar from './Navbar';
import './Designs.css';
import videoSrc from '../media/Designs_BackgroundVid.mp4';
import redLamp from '../media/Red_Lamp.JPG';
import yellowLamp from '../media/Yellow_Lamp.JPG';
import handCrafts from '../media/Hand_Crafts.JPG';
import blinds from '../media/Blinds.JPG';
import logoMartins from '../media/Logo_Martins.png';
import cvNiceVideo from '../media/CVNice.mov';
import Footer from './Footer';

const Designs = () => {
    return (
        <div className="designs">
            <Navbar />
            <div className="video-background">
                <video autoPlay muted loop>
                    <source src={videoSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className="designs-content">
                <h1>Designs</h1>
                <p>We at Code Long ask all the right questions and listen carefully so that our information and research can contribute to producing designs and content that accurately reflect you.</p>
                <p>Take one of our clients for example, St Martin’s Designs, ran by Tracey & Laura Holloway, this mother and daughter duo of professional textile designers who tailor make some of the most elegant of soft furnishings including lampshades, curtains, cushions and specializes in the fine art of Roman Blinds making, a very sought after skill to have in their profession.</p>
                <p>Just take a look for yourself at their unique and elegant Web Design we made for their business: <a href="https://stmartinsdesigns.co.uk" target="_blank">St Martin's Designs</a></p>
                <img src={redLamp} alt="Red Lamp" className="large-image" />
                <img src={yellowLamp} alt="Yellow Lamp" className="large-image" />
                <img src={handCrafts} alt="Hand Crafts" className="large-image" />
                <img src={blinds} alt="Blinds" className="large-image" />
                <p>We champion this business for their commitment to crafting the best luxury soft furnishings for their clients.</p>
                <a href="https://stmartinsdesigns.co.uk"><img src={logoMartins} alt="St Martins Designs Logo" className="large-image" /></a>
                <p>We at Code Long tailor made a web design, marketing materials and copy that represented St Martin’s Designs for who they are in order to engage and grow their audience.</p>
                <h2>CV Nice Collaboration</h2>
                <p>Here is a collaboration we made with a friend Chris Walters of Intrinsic Development Corporation Limited. CV Nice is a website which Harry and Chris used open source softwares in an effort to take user input and generate tailored CVs and cover letters with AI.</p>
                <p>The purpose was to alleviate the hardships of manually adjusting details for specific job applications. It was a challenging and exciting project to work on and the Github is public for viewing.</p>
                <a href="https://github.com/chwalters/cvnice" className="github-link">View on GitHub</a>
                <video autoPlay muted loop className="full-width-video">
                    <source src={cvNiceVideo} type="video/mp4" />
                </video>
            </div>
            <Footer />
        </div>
    );
};

export default Designs;