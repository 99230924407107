import React, { useState } from 'react';
import Navbar from './Navbar';
import './Contact.css';
import videoSrc from '../media/Code_Long.mp4';
import Footer from './Footer';

const Contact = () => {
    const [messageSent, setMessageSent] = useState(false); // State to handle success message

    const handleSubmit = (e) => {
        e.preventDefault();
        setMessageSent(true);  // Show success message upon form submit
        // Perform actual form submission here or let the form action handle it
    };

    return (
        <div className="contact-page">
            <Navbar />
            <div className="video-background">
                <video autoPlay muted loop>
                    <source src={videoSrc} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className="contact-container">
                <div className="contact-details">
                    <h2>Contact Us</h2>
                    <p className="centered">Phone: (+44) 07873104266</p>
                    <p className="centered">Email: harrylong96@yahoo.com</p>
                    
                    {/* Social Media Logos and Links */}
                    <div className="social-media">
                        <div className="social-item">
                            <img src={require('../media/Insta_Logo.png')} alt="Instagram" className="social-logo" />
                            <a href="https://www.instagram.com/codelong_?igsh=aXpvdTIyeng4aDFn&utm_source=qr" target="_blank" rel="noopener noreferrer">Follow us on Instagram</a>
                        </div>
                        <div className="social-item">
                            <img src={require('../media/Facebook_Logo.png')} alt="Facebook" className="social-logo" />
                            <a href="https://www.facebook.com/share/7fpVLP7WsMRSARKJ/?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">Follow us on Facebook</a>
                        </div>
                        <div className="social-item">
                            <img src={require('../media/GitHub_Logo.png')} alt="GitHub" className="social-logo" />
                            <a href="https://github.com/HazzaLong" target="_blank" rel="noopener noreferrer">Follow us on GitHub</a>
                        </div>
                    </div>
                </div>
                
                {/* Show success message if form submitted */}
                {messageSent ? (
                    <p className="success-message">Thank you for your message!</p>
                ) : (
                    <form
                        action="https://formie.io/form/2c508e4e-c8ca-44d1-8540-db13374ee0ba"
                        method="POST"
                        className="contact-form"
                        onSubmit={handleSubmit}
                    >
                        <h3>Send Us a Message</h3>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input type="text" id="name" name="name" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" name="email" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea id="message" name="message" rows="5" required></textarea>
                        </div>
                        <button type="submit">Send</button>
                    </form>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default Contact;