import React from 'react';
import { Link } from 'react-router-dom';
import './SideMenu.css';

const SideMenu = ({ isOpen, toggleMenu }) => {
    return (
        <div className={`side-menu ${isOpen ? 'open' : ''}`}>
            <button className="close-btn" onClick={toggleMenu}>×</button>
            <ul>
                <li><Link to="/services" onClick={toggleMenu}>Services</Link></li>
                <li><Link to="/designs" onClick={toggleMenu}>Designs</Link></li>
                <li><Link to="/about" onClick={toggleMenu}>About</Link></li>
                <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
            </ul>
        </div>
    );
};

export default SideMenu;
