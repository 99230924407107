import React from 'react';
import './Footer.css';
import instaLogo from '../media/Insta_Logo.png';
import facebookLogo from '../media/Facebook_Logo.png';
import gitHubLogo from '../media/GitHub_Logo.png';
import codeLongGold from '../media/Code_Long_Gold.png';
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                {/* Two logos on desktop */}
                <div className="logo-left">
                    <img src={codeLongGold} alt="Code Long Gold" className="footer-logo" />
                </div>
                <div className="logo-right">
                    <img src={codeLongGold} alt="Code Long Gold" className="footer-logo" />
                </div>

                {/* Social Media and Page Links */}
                <div className="social-media">
                    <div className="social-item">
                        <img src={instaLogo} alt="Instagram" className="social-logo" />
                        <a href="https://www.instagram.com/codelong_" target="_blank" rel="noopener noreferrer">Instagram</a>
                    </div>
                    <div className="social-item">
                        <img src={facebookLogo} alt="Facebook" className="social-logo" />
                        <a href="https://www.facebook.com/share/7fpVLP7WsMRSARKJ/" target="_blank" rel="noopener noreferrer">Facebook</a>
                    </div>
                    <div className="social-item">
                        <img src={gitHubLogo} alt="GitHub" className="social-logo" />
                        <a href="https://github.com/HazzaLong" target="_blank" rel="noopener noreferrer">GitHub</a>
                    </div>
                </div>

                {/* Page Links */}
                <div className="footer-links">
    <Link to="/services">Services</Link>
    <Link to="/designs">Designs</Link>
    <Link to="/about">About</Link>
    <Link to="/contact">Contact</Link>
</div>

                {/* Phone Number and Copyright */}
                <div className="phone-number">(+44) 07873104266</div>
                <div className="copyright">Code Long Copyright 2024</div>

                {/* Single logo for mobile view below all content */}
                <img src={codeLongGold} alt="Code Long Gold" className="footer-logo-mobile" />
            </div>
        </footer>
    );
};

export default Footer;